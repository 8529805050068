<template>
  <div class="drop-down-setting-Immo">
    <v-row class="flex-nowrap flex-center">
      <v-col>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          origin="top right"
          :nudge-width="20"
          content-class="popover-menu-setting"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <div class="profile-pic-immo" :style="computedImageUser">
                <!-- v-if="currentUser.photo == null" -->
                <img src="@/assets/img/avatar-ila.png" width="200" />
                <!-- <img v-else :src="currentUser.photo" width="200" /> -->
              </div>
              <v-col>
                <div v-if="currentUser" class="box-user-details-style">
                  <div class="name-user-style-Immo flex-center flex-wrap">
                    <span style="color: white; margin-bottom: 7px;">
                      {{ currentUser.first_name }}
                      {{ currentUser.last_name }}</span
                    >
                    <span v-if="currentUser.type == 'user.regie'" class="type">
                      Régie
                    </span>
                    <span
                      v-else-if="currentUser.type == 'user.commercial'"
                      class="type"
                    >
                      Commercial
                    </span>
                    <span v-else class="type">
                      {{ currentUser.details.title }}
                      {{
                        currentUser.details.team &&
                        currentUser.details.team.data &&
                        currentUser.details.team.data.type == 'regie'
                          ? '(Régie)'
                          : ''
                      }}
                      {{
                        currentUser.details.team &&
                        currentUser.details.team.data &&
                        currentUser.details.team.data.type == 'filiale'
                          ? '(Filiale)'
                          : ''
                      }}
                    </span>
                  </div>
                </div>
                <div v-else class="box-user-details-style">
                  template
                  <span class="name-user-style">
                    Profil
                  </span>
                  <span class="type">Inconnu</span>
                </div>
              </v-col>
              <font-awesome-icon
                :icon="['fass', 'chevron-down']"
                style="color: white; background-color: white;"
              />
            </v-btn>
          </template>
          <v-card>
            <v-list class="list-setting">
              <!-- <v-list-item
                  class="custom-hover-effect"
                  v-if="(currentUser && currentUser.isSuper) || computedRegie"
                >
                  <v-list-item-action @click="routerLinkSetting">
                    Paramètres
                  </v-list-item-action>
                </v-list-item> -->
              <v-list-item class="custom-hover-effect">
                <v-list-item-action @click="routerLinkPassword">
                  {{
                    $i18n.locale === 'fr'
                      ? 'Modification de mot de passe'
                      : 'Change password'
                  }}
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item class="custom-hover-effect">
                <v-list-item-action @click="deconnexion">
                  {{ $i18n.locale === 'fr' ? 'Se déconnecter' : 'Sign out' }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      menu: false
    }
  },
  methods: {
    ...mapActions(['logout']),
    deconnexion() {
      this.logout()
      localStorage.clear()
    },
    routerLinkSetting() {
      this.$router.push('/setting')
      this.menu = false
    },
    routerLinkPassword() {
      if (this.$route && this.$route.name != 'password') {
        this.$router.replace('/password')
      }
      this.menu = false
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    computedRegie: function() {
      return (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie'))
      )
    },
    computedImageUser() {
      if (this.currentUser && this.currentUser.image) {
        return {
          'background-image': 'url(' + `${this.currentUser.image}` + ')'
        }
      } else {
        return { 'background-color': '#F2F2F2' }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-icon-Nom {
  background-color: red;
  color: red;
}
.popover-menu-setting {
  right: 1px;
  left: unset !important;
  top: 65px !important;
}
@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-hover-effect {
  .v-list-item__action {
    width: 100% !important;
    margin-right: 20px !important;
  }
  &:hover {
    background-color: #f1f1f1;
  }
}
$circleSize: 35px;
$radius: 100px;
$fontColor: rgb(250, 250, 250);

.profile-pic-immo {
  color: transparent;
  @include object-center;
  position: relative;
  height: 40px !important;
  width: 40px !important;
  margin-bottom: 7px !important;
  margin-left: 3px !important;
  background-color: transparent !important;
  margin-right: -10px;

  input {
    display: none;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: $circleSize;
    height: $circleSize;
    border-radius: $radius;
    z-index: 0;
  }

  .-label {
    cursor: pointer;
    height: $circleSize;
    width: $circleSize;
  }

  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10000;
      color: $fontColor;
      transition: background-color 0.2s ease-in-out;
      margin-bottom: 0;
    }
  }

  span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }
}
.avatar-user {
  width: 40px;
  height: 40px;
}
.drop-down-setting-Immo {
  margin-right: 80px;

  .v-btn__content {
    transition: box-shadow 0.3s !important; /* Transition de 0.3 seconde pour l'effet de hover */

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important; /* Ajoute une ombre au survol */
    }
  }
  .v-btn--icon {
    color: #ffffff00 !important;
  }
}
.list-setting {
  cursor: pointer;
  box-shadow: none !important;
}
.icon-user-style {
  background-color: #fff !important;
  width: 42px;
  height: 42px;
  overflow: hidden;
  background-size: contain;
  background-position: 50%;
  border-radius: 50px;
  background-repeat: no-repeat;
  .icon {
    color: white;
    height: 30px;
    font-size: 25px;
  }
}
.box-user-details-style {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  text-align: end;
  margin: auto;
  // margin-right: 4px;
  // width: 145px;
  // width: 27vh;
  width: max-content;
  // margin-left: 21px;
  // padding-top: 11px;
  .name-user-style-Immo {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    color: rgb(60, 69, 75);
    .margin-line {
      margin-left: 40px;
    }
  }
  .role-user-style {
    font-size: 10px;
    font-weight: 400;
    color: #aaaaaa;
  }
}
</style>
